import React, { useEffect, useState } from "react";

// CSS
import "../css/map.css";

// Leaflet Map App
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from "react-leaflet";

// Main functions
const mainFunctions = require("../functions/main-functions");

// Map's params
const position = [37.8, -96];
const zoom = 4;

// Map's marker's icons
const customMarker = new L.Icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png"
});

// MAP DISPLAY COMPONENT
const MapDisplay = () => {

  // States
  const [gpxData, setGpxData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [trackColor, setTrackColor] = useState([]);
  const [markers, setMarkers] = useState([]);

  // Side effects
  useEffect(() => {
    // Set GPX
    const setGpx = async () => {
      // Get all gpx filenames
      const gpxFilenamesArray = await mainFunctions.getGpxFiles();

      let positionsResResultArray = [];

      // Get informations foreach gpx file
      gpxFilenamesArray.sort().forEach(async (gpxName, i) => {
        var filepath = window.location.href + "/gpx/" + encodeURIComponent(gpxName) + ".gpx";
        var filepath = "/gpx/" + encodeURIComponent(gpxName) + ".gpx";
        console.log("Getting file: " + filepath)

        const parseGpxData = await mainFunctions.new_parseGpxFile(filepath);
        // console.log("ParseGpxData: " + parseGpxData);
        var datetime  = parseGpxData[2];
        var distance  = parseGpxData[0];
        var elevation = parseGpxData[1];
        var track     = parseGpxData.slice(3);
        let markerData = [datetime, distance, elevation, track[0]];

        setTrackColor((oldArray) => [...oldArray, "#00ffff"]);

        positions.push(track[0]);
        markers.push(markerData);
        setGpxData((oldArray) => [...oldArray, track]);

      });
    };

    setGpx();
  }, []);

  return (
    <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Multiple tracks */}

      {gpxData.map((dataTrack, i) => {
        return (
          <Polyline
            key={i}
            pathOptions={{ fillColor: "red", color: trackColor[i] }}
            positions={dataTrack}
          />
        );
      })}

      {/* multiple markers */}

      {markers.map(marker =>
        <Marker position={marker[3]} icon={customMarker}>
            <Popup className="popupMarker">
                        Day: {marker[0]} <br/> Distance: {marker[1]} mi, Elevation: {marker[2]} ft
            </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapDisplay;
