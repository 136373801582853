import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, DefaultRoute, Switch } from 'react-router-dom';

import "./css/App.css";
import "./css/leaflet.css"

import Home from "./pages/Home";
import MapVisual from "./pages/MapVisual";
import NotFound from "./pages/NotFound";

function App() {
    const [ip, setIp] = useState('');

    useEffect(() => {
        fetch('/api/get-headers')
            .then(response => response.text())
            .then(data => {
                setIp(data);
                console.log(ip);
        });
    }, []);

    return (
        <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/map' element={<MapVisual />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
        </Router>
    )
}

export default App;
