// NPM
const axios = require("axios");
const gpxParser = require("gpxparser");

export async function new_parseGpxFile(gpxXmlFile) {
    return new Promise(async (resolve, reject) => {
        try {
            axios
            .get(gpxXmlFile, {
                    "Content-Type": "application/xml; charset=utf-8",
                })
            .then(async (response) => {
                    var gpxStr = response.data;
                    var gpx = new gpxParser();
                    gpx.parse(gpxStr);

                    var distance = gpx.tracks[0].distance.total * 0.000621371; // get distance in miles
                    var elevation= gpx.tracks[0].elevation.pos * 3.28084;      // get distance in feet
                    var time     = gpx.tracks[0].points[0].time.toISOString().substring(0, 10);

                    var output = gpx.tracks[0].points.map(p => [p.lat, p.lon]);
                    output.unshift(distance.toFixed(1), elevation.toFixed(1), time);

                    resolve(output);
                })
        } catch (error) {
          console.error(":( parseGpxFile error");
          reject(console.log);
        }
    })
}

// Parse gpx file
export async function parseGpxFile(gpxXmlFile) {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(gpxXmlFile, {
          "Content-Type": "application/xml; charset=utf-8",
        })
        .then(async (response) => {
          // Gpx file string
          var gpxStr = response.data;

          // Count lat & lon
          var trkCount = gpxStr.match(/<trk>/g).length;

          // While loop params
          var i = 0;
          var resultArray = [];

          while (i < trkCount) {
            // Number of total characters
            let totalGpxStr = gpxStr.length;

            // Slice str to each <trk></trk> segment
            let trkPosition = gpxStr.indexOf("<trk>");
            let trkLastPosition = gpxStr.indexOf("</trk>") + "</trk>".length;
            let trkStr = gpxStr.substring(trkPosition, trkLastPosition);

            // If <trk></trk> is existing
            if (trkPosition > 0) {
              // Redefine the native string
              gpxStr = gpxStr.substring(trkLastPosition, totalGpxStr);

              resultArray.push(trkStr);
            }

            // Incrementation
            i++;
          }

          // Foreach loop params
          var resultArrObj = [];

          // Get all latitudes and longitudes data for each track
          for (let f = 0; f < resultArray.length; f++) {
            // Variables params
            var track = resultArray[f];

            // Track's name
            let namePosition =
              resultArray[f].indexOf("<name>") + "<name>".length;
            let nameLastPosition = resultArray[f].indexOf("</name>");
            let trackName = resultArray[f].substring(
              namePosition,
              nameLastPosition,
            );

            // Params
            var n = 0;
            var arr = [];

            // Count lat & lon
            var latLonCount = resultArray[f].match(/lat=/g).length;

            while (n < latLonCount) {
              // Number of total characters
              let totalStr = track.length;

              // Selection of string
              var selectedStrPosition = track.indexOf("<trkpt");
              var selectedStrLastPosition = track.indexOf("</trkpt>");
              var selectedStr = track.substring(
                selectedStrPosition,
                selectedStrLastPosition,
              );

              // Get elevations
              var selectedElevationStrPosition =
                track.indexOf("<ele>") + "<ele>".length;
              var selectedElevationStrLastPosition = track.indexOf("</ele>");
              var selectedElevationsStr = track.substring(
                selectedElevationStrPosition,
                selectedElevationStrLastPosition,
              );
              var eleValue = parseFloat(selectedElevationsStr);

              // Get latitude and longitude between double quotes from the string
              var reg = new RegExp(/"(.*?)"/g); // Double quotes included
              var matches = selectedStr.match(reg);
              var matchesArr = [];

              // Record matches
              for (let match of matches) {
                // Match convert to number format
                let v = parseFloat(match.replace(/['"]+/g, ""));

                // Record
                matchesArr.push(v);
              }

              // Latitude value
              let latValue = matchesArr[0];

              // Longitude value
              let lonValue = matchesArr[1];

              // If <trkpt></trkpt> is existing
              if (selectedStrPosition > 0) {
                // Redefine the native string
                track = track.substring(selectedStrLastPosition + 5, totalStr);

                // Record
                arr.push([latValue, lonValue, eleValue]);
              }

              // Incrementation
              n++;

              // While loop end
              if (n === latLonCount) {
                // Remove duplicated values
                let stringArray = arr.map(JSON.stringify);
                let uniqueStringArray = new Set(stringArray);
                let uniqueArray = Array.from(uniqueStringArray, JSON.parse);

                // Result object
                let obj = {
                  id: f,
                  name: trackName,
                  positions: uniqueArray,
                };

                //console.log(obj);

                // Record
                resultArrObj.push(obj);
              }
            }

            // For loop end
            if (f + 1 === resultArray.length) {
              resolve(resultArrObj);
            }
          }
        });
    } catch (error) {
      console.error(":( parseGpxFile error");
      reject(console.log);
    }
  });
}

// Get tracks positions
export async function getTracksPositions(parseGpxData) {
  return new Promise(async (resolve, reject) => {
    try {
      // Params
      var a = [];

      parseGpxData.forEach((element, i) => {
        // Positions record
        a.push(element.positions);

        // Foreach loop end
        if (i + 1 === parseGpxData.length) {
          resolve(a);
        }
      });
    } catch (error) {
      console.error(":( getTracksPositions error");
      reject(console.log);
    }
  });
}

// Random colors from palette
export async function randomColorPalette() {
  return new Promise(async (resolve, reject) => {
    try {
      // Colors params
      let colors = {
        aqua: "#00ffff",
        blue: "#0000ff",
        cyan: "#00ffff",
        fuchsia: "#ff00ff",
        green: "#008000",
        indigo: "#4b0082",
        lime: "#00ff00",
        magenta: "#ff00ff",
        navy: "#000080",
        orange: "#ffa500",
        purple: "#800080",
        violet: "#800080",
        red: "#ff0000",
        yellow: "#ffff00",
      };

      // Get all values of colors objec)
      const values = Object.values(colors);

      // Get one random color
      var color = values[Math.floor(Math.random() * values.length)];

      resolve(color);
    } catch (error) {
      console.error(":( randomColorPalette error");
      reject(console.log);
    }
  });
}

export async function getGpxFiles() {
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .get("/gpx/manifest.json")
                .then(async (response) => {
                    var files = response.data.split("\n");
                    files.pop(); // deal with whitespace
                    resolve(files);
                });
        } catch (error) {
            console.error("L you got a getGpxFiles error");
            reject(console.log);
        }
    });
}
