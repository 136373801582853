// autogen imports
import React from "react";

import "../css/leaflet.css"
import Map from "../components/map"
import Dropdown from "../components/Dropdown"

const MapVisual = () => {
    return (
        <>
        <div className="MapVisual">
        <Dropdown></Dropdown>
            <Map />
        </div>
        </>
    );
}

export default MapVisual;
