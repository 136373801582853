import React, { useState } from 'react';
import '../css/Dropdown.css'

const menuIcon = "Hamburger_icon.svg";

const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    function toggleHamburger() {
        var btn = document.getElementById("Hammy");
        btn.classList.toggle("change");
    }

    function hoveringOn() {
        setIsOpen(true);
        var btn = document.getElementById("Hammy");
        btn.classList.add("change");
    }

    function hoveringOff() {
        setIsOpen(false);
        var btn = document.getElementById("Hammy");
        btn.classList.remove("change");
    }

    return (
        <div className="dropdown" id="Hammy" onClick={toggleDropdown, toggleHamburger}
                                  onMouseEnter={hoveringOn}
                                  onMouseLeave={hoveringOff}>
            <div class="container" id="Hammy" onclick="myFunction(this)">
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </div>

            {isOpen && (
               <div className="dropdownContent">
                 <a href="/">Home</a>
                 <a href="/map">Adventure Map</a>
               </div>
            )}
        </div>
    );
};

                 // <a href="/resume">Resume</a>
export default Dropdown;
